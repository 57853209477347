/* eslint-disable consistent-return */
<template>
	<div class="meeting">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="handleSearch" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="会议主题">
				<Input placeholder="请输入会议主题" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--@onSearch="searchHandle"
  -->
		<!-- <p>会议主题</p>
      <div class="search">
        <Input
          @keyup.enter.native="getListData"
          v-model="title"
          placeholder="请输入..."
          style="width: 300px"
        ></Input>
        <Button class="queryBtn" type="primary" @click="getListData">查询</Button>
        <Button class="resetBtn"  @click="reset">重置</Button>
      </div> -->
		<!-- "
 -->
		<Button
			class="m15"
			v-if="isPermission('btn_project_meeting_add')"
			@click="showDrawer"
			type="primary"
			>新增</Button
		>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns1"
			:data="data"
			:page="page"
		>
			<template slot="action" slot-scope="scope">
				<div class="btnSlot">
					<span
						class="cursor"
						v-if="isPermission('btn_project_meeting_view')"
						@click="seeEdit(scope.row, 'see')"
						>查看</span
					>
					<span class="conduit">|</span>
					<span
						class="cursor"
						v-if="isPermission('btn_project_meeting_update')"
						@click="seeEdit(scope.row, 'edit')"
						>修改</span
					>
					<span class="conduit">|</span>
					<Poptip
						confirm
						placement="left-end"
						transfer
						title="您确认删除这条数据吗？"
						@on-ok="ok(scope.row)"
					>
						<span class="cursor" v-if="isPermission('btn_project_meeting_del')"
							>删除</span
						>
					</Poptip>
				</div>
			</template>
		</CtmsDataGrid>

		<Modal
			:title="head"
			:value="visible"
			width="450"
			:mask-closable="false"
			@on-cancel="onCancel"
		>
			<Form
				:disabled="edit === 'see'"
				:model="model"
				ref="form"
				label-position="top"
				:rules="rules"
			>
				<Row :gutter="32">
					<Col span="24">
						<FormItem label="会议主题" prop="title">
							<Input placeholder="请输入会议主题" v-model.trim="model.title" />
						</FormItem>
						<FormItem label="会议时间" prop="time">
							<DatePicker
								v-model="model.time"
								type="datetimerange"
								style="width: 418px"
								format="yyyy-MM-dd HH:mm"
								placeholder="请输入会议时间"
								@on-change="timeChange"
							></DatePicker>
						</FormItem>
						<FormItem label="会议地点" prop="address">
							<Input
								placeholder="请输入会议时间"
								v-model.trim="model.address"
							/>
						</FormItem>
						<FormItem label="会议附件">
							<Upload
								:action="action"
								:default-file-list="defaultFileList"
								:before-upload="beforeUpload"
								ref="upload"
								:headers="{ token: $store.state.user.token }"
								:on-remove="onRemove"
								:on-success="onSuccess"
								:on-preview="onPreview"
								:max-size="10485760"
								:on-exceeded-size="onExceededSize"
							>
								<Button icon="ios-cloud-upload-outline">上传</Button>
								<p style="color: red">文件大小建议在2G以内，最大不超过10G</p>
							</Upload>
						</FormItem>
					</Col>
				</Row>
			</Form>
			<div slot="footer">
				<Button style="margin-right: 8px" @click="onCancel">取消</Button>
				<Button
					@click="submit"
					:loading="addLoading"
					:disabled="edit == 'see'"
					type="primary"
					>确定</Button
				>
			</div>
		</Modal>

		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="'trainViewDocumentPreview'"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</div>
</template>

<script>
import { formDataToMD5 } from "@/utils/util"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import { mapState } from "vuex"
import docType from "@/config/docType"
import {
	addMeeting,
	queryMeeting,
	delMeeting,
	queryMeetingInfo,
	uploadMeetingInfo
} from "@/api/project/conferenceTheme"

export default {
	components: {
		DocumentPreview
	},
	data() {
		return {
			addLoading: false,
			searchParams: {},
			loading: false,
			head: "会议纪要",
			documentPreviewVisible: false,
			documentSrc: "",
			imgSrc: "",
			row: {},
			edit: "",
			defaultFileList: [],
			action: "",
			extraData: {
				md5: ""
			},
			visible: false,
			title: "",
			columns1: [
				{
					title: "会议主题",
					key: "title"
				},
				{
					title: "会议时间",
					render: (h, { row }) => h("div", `${row.startTime}--${row.endTime}`)
				},
				{
					title: "会议地点",
					key: "address"
				},
				{
					title: "操作",
					width: "150",
					slot: "action"
				}
			],
			data: [],
			rules: {
				title: [
					{
						required: true,
						message: "会议主题必填",
						trigger: "blur,change"
					},
					{
						type: "string",
						max: 50,
						message: "至多录入50个汉字长度",
						trigger: "blur,change"
					}
				],
				address: [
					{
						required: true,
						message: "会议地点必填",
						trigger: "blur,change"
					},
					{
						type: "string",
						max: 50,
						message: "至多录入50个汉字长度",
						trigger: "blur,change"
					}
				],
				time: [
					{
						required: true,
						type: "array",
						message: "请输入时间",
						trigger: "blur,change",
						fields: {
							0: {
								type: "date",
								required: true,
								message: "请输入开始时间"
							},
							1: {
								type: "date",
								required: true,
								message: "请输入结束时间"
							}
						}
					}
				]
			},
			model: {
				title: "",
				address: "",
				time: [null, null]
			},
			fileIds: [],
			startTime: "",
			endTime: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	created() {
		this.getListData()
	},
	computed: {
		...mapState("permission", ["projectActionPermissions"]),
		...mapState({
			dataGridHeight: state => state.contentHeight - 235
		}),
		projectId() {
			return this.$route.params.projectId
		}
	},
	methods: {
		async seeEdit(row, edit) {
			this.visible = true
			this.edit = edit
			this.row = row
			if (edit === "see") {
				this.head = "查看会议纪要"
			} else {
				this.head = "修改会议纪要"
			}
			const { data } = await queryMeetingInfo({
				projectId: this.projectId,
				meetingId: row.id
			})
			this.$nextTick(() => {
				this.model.title = data.title
				this.model.address = data.address
				this.model.time = [new Date(data.startTime), new Date(data.endTime)]
				this.timeChange([data.startTime, data.endTime])
				this.defaultFileList = data.files
				this.defaultFileList.forEach(item => {
					// eslint-disable-next-line no-param-reassign
					item.response = {
						data: item.id
					}
				})
				this.fileIds = data.files.map(item => item.id)
			})
		},
		onCancel() {
			this.visible = false
			this.$nextTick(() => {
				this.$refs.form.resetFields()
				this.model.time = [null, null]
				this.fileIds = []
				this.defaultFileList = []
			})
		},
		timeChange(value) {
			const [startTime, endTime] = value
			this.startTime = startTime
			this.endTime = endTime
		},
		submit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					if (this.edit === "edit") {
						uploadMeetingInfo({
							projectId: this.projectId,
							fileIds: this.fileIds,
							startTime: this.startTime,
							endTime: this.endTime,
							title: this.model.title,
							address: this.model.address,
							id: this.row.id
						}).then(res => {
							if (res.code === 0) {
								this.$Message.success("操作成功")
								this.onCancel()
								this.getListData()
							} else {
								this.$Message.error(res.message)
							}
						})
					} else {
						addMeeting({
							projectId: this.projectId,
							fileIds: this.fileIds,
							startTime: this.startTime,
							endTime: this.endTime,
							title: this.model.title,
							address: this.model.address
						}).then(res => {
							if (res.code === 0) {
								this.$Message.success("操作成功")
								this.onCancel()
								this.getListData()
							} else {
								this.$Message.error(res.message)
							}
						})
					}
				}
			})
		},
		// 文件上传前
		beforeUpload(file) {
			this.addLoading = true
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				new Promise(relove => {
					this.action = `${this.$baseUrl}/project-meeting/${
						this.projectId
					}/meeting/upload-files?md5=${this.extraData.md5}`
					relove()
				}).then(() => {
					this.$refs.upload.post(file)
				})
			})
			return false
		},
		onSuccess(response, file, fileList) {
			this.fileIds = fileList.map(item => item.response.data)
			this.addLoading = false
			this.$Message.success("上传成功")
		},
		onRemove(file, fileList) {
			this.fileIds = fileList.map(item => item.response.data)
		},
		showDrawer() {
			this.visible = true
			this.edit = "add"
			this.head = "新增会议纪要"
			this.addLoading = false
		},
		reset() {
			this.searchParams.name = ""
			this.handleSearch()
		},
		handleSearch() {
			this.page.current = 1
			this.getListData()
		},
		isPermission(code) {
			return this.projectActionPermissions.indexOf(code) > -1
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.getListData()
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.current = 1
			this.page.pageSize = pageSize
			this.getListData()
		},
		async getListData() {
			this.loading = true
			const { data: list } = await queryMeeting({
				projectId: this.projectId,
				pageNum: this.page.current,
				pageSize: this.page.pageSize,
				title: this.searchParams.name
			})
			this.$nextTick(() => {
				this.data = list.list
				this.page.total = list.total
			})
			this.loading = false
		},
		async ok(row) {
			const { code, message } = await delMeeting({
				projectId: this.projectId,
				meetingId: row.id
			})
			if (code === 0) {
				this.$Message.success("操作成功")
				this.getListData()
			} else {
				this.$Message.error(message)
			}
		},
		onPreview(file) {
			// const type = file.suffix && file.suffix.toUpperCase();
			const type = file.name
				.split(".")
				[file.name.split(".").length - 1].toUpperCase()
			if (type && docType.previewTypes.includes(type)) {
				// 预览
				this.documentSrc = `${this.$baseUrl}/project-meeting/${this.projectId}/review-file/${
					file.response.data
				}?token=${this.$store.state.user.token}`
				this.documentPreviewVisible = true
			} else {
				// 下载
				const url = `${this.$baseUrl}/project-meeting/${this.projectId}/download-file/${
					file.response.data
				}?token=${this.$store.state.user.token}`
				window.open(url)
			}
		},
		onExceededSize() {
			this.$Message.error("文件超出大小限制")
		}
	}
}
</script>

<style lang="less">
.meeting {
	width: 100%;
	height: 100%;
	background-color: #fff;
	padding: 20px;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 5px;
}

.m15 {
	margin: 15px 0;
}
.btnSlot {
	display: flex;
	justify-content: space-between;
	color: #2d8cf0;
	//color: #f3a32e;
	.cursor {
		cursor: pointer;
	}
}
.conduit {
	position: relative;
	top: -0.08em;
	font-size: 14px;
	cursor: auto;
	font-variant: tabular-nums;
	line-height: 1.5;
	font-family:
		Chinese Quote,
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		PingFang SC,
		Hiragino Sans GB,
		Microsoft YaHei,
		Helvetica Neue,
		Helvetica,
		Arial,
		sans-serif;
	vertical-align: middle;
	color: #e8e8e8;
	line-height: 1.5;
}
</style>
