import request from "@/utils/request"

const moduleName = "/project-meeting"

// eslint-disable-next-line no-multi-spaces
export const addMeeting = data =>
	request(`${moduleName}/${data.projectId}/meeting`, {
		// 新增会议信息
		method: "post",
		body: data
	})

export const queryMeeting = data =>
	request(`${moduleName}/${data.projectId}/page`, {
		// 查询会议分页列表
		method: "get",
		params: data
	})

export const delMeeting = data =>
	request(`${moduleName}/${data.projectId}/meeting/${data.meetingId}`, {
		// 删除会议信息
		method: "delete",
		params: data
	})

export const queryMeetingInfo = data =>
	request(`${moduleName}/${data.projectId}/meeting/${data.meetingId}`, {
		// 查询会议信息详情
		method: "get",
		params: data
	})

export const uploadMeetingInfo = data =>
	request(`${moduleName}/${data.projectId}/meeting/${data.id}`, {
		// 更新会议信息;
		method: "put",
		body: data
	})
