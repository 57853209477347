export default {
	PHOTO: ["PNG", "JPG", "JPEG", "GIFT", "TIFF"],
	EXCEL: ["XLSX"],
	DOC: ["DOC", "DOCX"],
	TXT: ["TXT"],
	PDF: ["PDF"],
	previewTypes: [
		"JPG",
		"JPEG",
		"BMP",
		"PNG",
		"GIF",
		"XLSX",
		"XLS",
		"DOC",
		"DOCX",
		"PDF"
	]
}
